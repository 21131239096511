import React, { useEffect, useRef, useState } from "react";
import { bootstrapCameraKit, createMediaStreamSource, Transform2D } from "@snap/camera-kit";
import { apiToken, defaultLensGroupId, vansLensGroupId } from "../../etc/constants";
import "./SnapCamera.css";
let mediaStream;

const SnapCamera = ({ lensIndex = 0, lensGroupId }) => {
  const canvasRef = useRef(null);
  const cameraSelectRef = useRef(null);
  const lensSelectRef = useRef(null);

  // console.log(lensIndex);
  // alert(lensGroupId);

  useEffect(() => {
    console.log("USE EFFECT CALLED");
    const init = async () => {
      const cameraKit = await bootstrapCameraKit({ apiToken: apiToken });
      const session = await cameraKit.createSession();

      // Use the ref to get the canvas element
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.replaceWith(session.output.live);
      }
      const { lenses } = await cameraKit.lensRepository.loadLensGroups([lensGroupId]);

      const myLens = lenses[lensIndex];

      console.log(lenses[lensIndex]);

      // Change Page Title
      document.title = myLens.name || "My Lens";

      // Change Fav Icon
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = myLens.iconUrl;

      session.applyLens(myLens);

      await setCameraKitSource(session);
      const backCameraId = await attachCamerasToSelect(session);

      // if (backCameraId) {
      //   alert("back camera ID was found");
      // } else {
      //   alert("back camera ID was NOT found");
      // }

      await setCameraKitSource(session, backCameraId);
    };

    init();
  }, []);

  const setCameraKitSource = async (session, deviceId) => {
    console.log("------ Setting Camera Kit Source ------");
    if (mediaStream) {
      session.pause();
      mediaStream.getVideoTracks()[0].stop();
    }

    mediaStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId } });

    const source = createMediaStreamSource(mediaStream);

    await session.setSource(source);

    // source.setTransform(Transform2D.MirrorX); // For selfie mode lenses

    session.play();
  };

  const attachCamerasToSelect = async (session) => {
    let backCameraId = undefined;

    cameraSelectRef.current.innerHTML = "";
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter(({ kind }) => kind === "videoinput");

    cameras.forEach((camera) => {
      const option = document.createElement("option");
      option.value = camera.deviceId;
      option.text = camera.label;
      cameraSelectRef.current.appendChild(option);
      console.log(option.text);

      // if(option.text === "OBS Virtual Camera") {
      if (option.text === "Back Camera") {
        backCameraId = option.value;
      }
    });

    cameraSelectRef.current.addEventListener("change", (event) => {
      const deviceId = event.target.selectedOptions[0].value;
      // console.log(deviceId)
      setCameraKitSource(session, deviceId);
    });

    return backCameraId;
  };

  const attachLensesToSelect = async (lenses, session) => {
    lensSelectRef.current.innerHTML = "";
    lenses.forEach((lens) => {
      const option = document.createElement("option");
      option.value = lens.id;
      option.text = lens.name;
      lensSelectRef.current.appendChild(option);
    });

    lensSelectRef.current.addEventListener("change", (event) => {
      const lensId = event.target.selectedOptions[0].value;
      const lens = lenses.find((lens) => lens.id === lensId);
      if (lens) session.applyLens(lens);
    });
  };

  return (
    <div className="container">
      <canvas ref={canvasRef} id="canvas-container" width="1920" height="1080"></canvas>
      <div className="footer">
        <select ref={cameraSelectRef} className="styled-select"></select>
        {/* <select ref={lensSelectRef} className="styled-select"></select> */}
      </div>
    </div>
  );
};

export default SnapCamera;
