import "./App.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

import SnapCamera from "./pages/SnapCamera/SnapCamera";
import Layout from "./pages/Layout";
import HomePage from "./pages/HomePage";
import NoPage from "./pages/NoPage";

import { firstLensGroupId, vansLensGroupId } from "./etc/constants";

const Lens = ({ lensGroupId }) => {
  let { lensIndex } = useParams();
  return <SnapCamera lensIndex={lensIndex} lensGroupId={lensGroupId} />;
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="vans" element={<SnapCamera lensIndex={0} lensGroupId={vansLensGroupId} />} />
          <Route path="vans/:lensIndex" element={<Lens lensGroupId={vansLensGroupId} />} />

          {/* HANDLE ANY LENS INDEX */}
          <Route path="lens/:lensIndex" element={<Lens lensGroupId={firstLensGroupId} />} />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
